import React, { useContext } from 'react';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SettingsContainer } from '@components';
import { EmergencySavingsDrawer } from './EmergencySavingsDrawer';

export const EmergencySettings = observer(() => {
  const [isEmergencySavingsOpen, setIsEmergencySavingsOpen] = React.useState(false);
  const {
    userStore: { settings },
  } = useContext(appContext);

  const sections = [
    {
      title: 'Emergency Savings',
      onEditClick: () => setIsEmergencySavingsOpen(true),
      items: [{ key: 'Interest Rate', value: `${settings?.emergencySavings?.interestRate}%` }],
    },
  ];

  return (
    <>
      <SettingsContainer title="Emergency Savings" sections={sections} />
      <EmergencySavingsDrawer
        open={isEmergencySavingsOpen}
        handleClose={() => setIsEmergencySavingsOpen(false)}
        settings={settings}
      />
    </>
  );
});
