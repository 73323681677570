import React, { useContext } from 'react';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SettingsContainer } from '@components';
import { ExpenseDrawer } from './ExpensesDrawer';

export const ExpensesSettings = observer(() => {
  const [isEmergencySavingsOpen, setIsEmergencySavingsOpen] = React.useState(false);
  const {
    userStore: { settings },
  } = useContext(appContext);

  const sections = [
    {
      title: 'Expenses',
      onEditClick: () => setIsEmergencySavingsOpen(true),
      items: [
        { key: 'Transportation', value: `${settings?.expenses?.thresholds?.transportationTotal}%` },
        { key: 'Housing', value: `${settings?.expenses?.thresholds?.housingTotal}%` },
        { key: 'Housing Utilities', value: `${settings?.expenses?.thresholds?.housingUtilities}%` },
        {
          key: 'Personal Care Clothing',
          value: `${settings?.expenses?.thresholds?.personalCareClothing}%`,
        },
        {
          key: 'Personal Care Groceries',
          value: `${settings?.expenses?.thresholds?.personalCareGroceries}%`,
        },
        {
          key: 'Personal Care Medical',
          value: `${settings?.expenses?.thresholds?.personalCareMedical}%`,
        },
        { key: 'Recreation', value: `${settings?.expenses?.thresholds?.recreationTotal}%` },
      ],
    },
  ];

  return (
    <>
      <SettingsContainer title="Expenses" sections={sections} />
      <ExpenseDrawer
        open={isEmergencySavingsOpen}
        handleClose={() => setIsEmergencySavingsOpen(false)}
        settings={settings}
      />
    </>
  );
});
