import React from 'react';
import Drawer from '@mui/material/Drawer/Drawer';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { COLORS } from '@styles';
import { Typography } from '@mui/material';

export const drawerStyle = {
  main: {
    height: '90%',
  } as React.CSSProperties,
  paperProps: {
    style: {
      backgroundColor: COLORS.NEUTRAL_BLACK,
      backgroundImage: 'none',
      width: '384px',
      padding: '1rem',
      height: '100vh',
      overflow: 'hidden',
    } as React.CSSProperties,
  },
  headerStyle: {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '1.5rem',
      fontWeight: '700',
      marginBottom: '1.5rem',
      marginTop: '20px',
      paddingInline: '8px',
    } as React.CSSProperties,
    leftSide: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    } as React.CSSProperties,
    headerIcon: {
      color: COLORS.ELEKTRAFI_CYAN_NEUTRAL,
    },
    headerLabel: {
      fontWeight: 'bold',
    },
  },
};

export interface ICustomDrawerProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  headerLabel: string;
  headerIcon: React.ReactNode;
}

export function CustomDrawer(props: ICustomDrawerProps) {
  const { open, onClose, headerLabel, headerIcon, children } = props;

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={drawerStyle.paperProps}>
      <div style={drawerStyle.main}>
        <header style={drawerStyle.headerStyle.container}>
          <div style={drawerStyle.headerStyle.leftSide}>
            <div style={drawerStyle.headerStyle.headerIcon}>{headerIcon}</div>
            <Typography variant="h5" sx={drawerStyle.headerStyle.headerLabel}>
              {headerLabel}
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </header>

        {children}
      </div>
    </Drawer>
  );
}