import { SuperAdminRole } from '@graphql';
import {
  AdvisorsPage,
  ForgotPasswordPage,
  LoginPage,
  ManagersPage,
  OrganizationDetailPage,
  OrganizationsPage,
  ResetPasswordPage,
  SettingsPage,
} from '@pages';
import { FC } from 'react';

type Route = Array<{
  path: string;
  component: FC;
  exact: boolean;
  name?: string;
  role?: SuperAdminRole;
}>;

type IRoutes = {
  [key in RouteType]: Route;
};

type AllowedPaths =
  | 'login'
  | 'reset'
  | 'forgot'
  | 'organizations'
  | 'advisors'
  | 'organizationDetail'
  | 'settings'
  | 'requests'
  | 'managers';

export const paths: { [key in AllowedPaths]: string } = {
  login: '/auth/login',
  reset: '/auth/reset/:tokenId/:userId',
  forgot: '/auth/forgot',
  organizations: '/organizations',
  advisors: '/advisors',
  organizationDetail: '/organizations/:id',
  settings: '/settings',
  requests: '/requests',
  managers: '/admins',
};

const routes: IRoutes = {
  public: [],
  auth: [
    { path: paths.organizations, component: OrganizationsPage, exact: true, name: 'Organizations' },
    { path: paths.advisors, component: AdvisorsPage, exact: true, name: 'Advisors' },
    { path: paths.organizationDetail, component: OrganizationDetailPage, exact: true },
    // { path: paths.requests, component: RequestsPage, exact: true,
    //   name:'Advisor Requests' },
    {
      path: paths.managers,
      component: ManagersPage,
      exact: true,
      name: 'Admins',
      role: SuperAdminRole.Admin,
    },
    {
      path: paths.settings,
      component: SettingsPage,
      exact: true,
      name: 'Global Settings',
      role: SuperAdminRole.Admin,
    },
  ],
  unAuth: [
    { path: paths.login, component: LoginPage, exact: true },
    { path: paths.forgot, component: ForgotPasswordPage, exact: true },
    { path: paths.reset, component: ResetPasswordPage, exact: true },
  ],
};

export const getRoutes = (type: RouteType, userRole?: SuperAdminRole): Route =>
  routes[type].filter((route) => !route.role || route.role === userRole);
