import React, { useContext } from 'react';
import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import { appContext } from '@utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
  formItems: {
    margin: '1rem 0',
  },
  formIcons: {
    cursor: 'pointer',
  },
});

export const EmergencySettingsOld = observer(() => {
  const sx = makeStyles();
  const {
    userStore: { settings, getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);
  return (
    <Formik
      initialValues={settings}
      enableReinitialize
      onSubmit={async (values) =>
        (await updateSettings(values)) &&
        (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
      }
    >
      {(formik) => (
        <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
          <Grid sx={sx.pageContainer} container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Interest Rate"
                name="emergencySavings.interestRate"
                onChange={formik.handleChange}
                value={formik.values.emergencySavings?.interestRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button fullWidth variant="contained" disabled={formik.isSubmitting} type="submit">
                SAVE
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});
