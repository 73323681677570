import { Button, CircularProgress,  } from '@mui/material';
import React, { MouseEventHandler, useMemo } from 'react';
import { COLORS } from '@styles';

const customButtonStyle = {
  height: `3rem`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  borderRadius: `0.5rem`,

  '&.default-variant': {
    backgroundColor: COLORS.ELEKTRAFI_NEUTRAL,
    color: COLORS.NEUTRAL_WHITE,
    '&.Mui-disabled': {
      backgroundColor: COLORS.NEUTRAL_DARK_1,
      color: COLORS.NEUTRAL_LIGHT_0,
    },
  },

  '&.transparent-variant': {
    backgroundColor: `transparent`,
    borderColor: COLORS.NEUTRAL_WHITE,
    color: COLORS.NEUTRAL_WHITE,
  },

  '&.destructive-variant': {
    backgroundColor: COLORS.NEGATIVE_NEUTRAL,
    color: COLORS.NEUTRAL_WHITE,
    '&.Mui-disabled': {
      backgroundColor: COLORS.NEUTRAL_DARK_1,
      color: COLORS.NEUTRAL_LIGHT_0,
    },
  },
};

export type TCustomButtonVariant = `default` | `transparent` | `destructive`;

interface ICustomButtonProps {
  variant: TCustomButtonVariant;
  children?: any;
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void);
  id?: string;
  isLoading?: boolean;
  type?: `submit` | `reset` | `button`;
  disabled?: boolean;
  width?: string;
}

const getCustomButtonProps = (variant: TCustomButtonVariant) => {
  switch (variant) {
    case `transparent`:
      return `outlined`;
    case `destructive`:
      return `contained`;
    default:
      return `contained`;
  }
};

export function CustomButton({
  variant,
  onClick,
  id,
  children,
  isLoading,
  type,
  disabled,
  width,
}: ICustomButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading || typeof onClick !== `function`) {
      return;
    }
    onClick(event);
  };

  const muiVariant = useMemo(() => getCustomButtonProps(variant), [variant]);

  return (
    <Button
      variant={muiVariant}
      onClick={handleClick}
      id={id}
      type={type}
      disabled={disabled}
      sx = {{ ...customButtonStyle, width}}
      className={`${variant}-variant`}
    >
      {isLoading ? <CircularProgress size={25} color="inherit" /> : children}
    </Button>
  )
}

CustomButton.defaultProps = {
  children: null,
  isLoading: false,
  type: `button`,
  disabled: false,
  width: `100%`,
  id: "",
  onClick: () => {},
};