import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import PercentIcon from '@mui/icons-material/Percent';
import { GlobalSettingsDebtTypes, globalSettingsStyles } from '../../utils';

interface IDebtDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
  debtType: GlobalSettingsDebtTypes;
}

export function DebtDrawer({ open, handleClose, settings, debtType }: IDebtDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel={`Edit ${debtType}`}
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          {debtType === GlobalSettingsDebtTypes.REFINANCE_WITHOUT_MORTGAGE && (
            <>
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Interest Rate"
                name="debt.refinancingWithoutMortgage.interestRate"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingWithoutMortgage?.interestRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Total Years To Pay Debt"
                name="debt.refinancingWithoutMortgage.totalYearsToPayDebt"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingWithoutMortgage?.totalYearsToPayDebt || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                }}
              />
            </>
          )}
          {debtType === GlobalSettingsDebtTypes.REFINANCE_MORTGAGE && (
            <>
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Interest Rate"
                name="debt.refinancingMortgage.interestRate"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingMortgage?.interestRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Total Years To Pay Debt"
                name="debt.refinancingMortgage.totalYearsToPayDebt"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingMortgage?.totalYearsToPayDebt || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                }}
              />
            </>
          )}
          {debtType === GlobalSettingsDebtTypes.REFINANCE_ALL_DEBTS && (
            <>
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Interest Rate"
                name="debt.refinancingByAllDebts.interestRate"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingByAllDebts?.interestRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Total Years To Pay Debt"
                name="debt.refinancingByAllDebts.totalYearsToPayDebt"
                onChange={formik.handleChange}
                value={formik.values.debt?.refinancingByAllDebts?.totalYearsToPayDebt || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                }}
              />
            </>
          )}
          {debtType === GlobalSettingsDebtTypes.MORTGAGE_QUALIFIER && (
            <>
              {' '}
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Down Payment"
                name="debt.mortgageQualification.downPayment"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.downPayment || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Total years To Pay Debt"
                name="debt.mortgageQualification.totalYearsToPayDebt"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.totalYearsToPayDebt || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Interest Rate"
                name="debt.mortgageQualification.interestRate"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.interestRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Property Insurance"
                name="debt.mortgageQualification.propertyInsurance"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.propertyInsurance || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Total Debt Service Ratio"
                name="debt.mortgageQualification.totalDebtServiceRatio"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.totalDebtServiceRatio || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Gross Debt Service Ratio"
                name="debt.mortgageQualification.grossDebtServiceRatio"
                onChange={formik.handleChange}
                value={formik.values.debt?.mortgageQualification?.grossDebtServiceRatio || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}
