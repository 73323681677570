import { CustomButton, CustomDrawer } from '@components';
import { ClientInput, ORGANIZATION_QUERY } from '@graphql';
import { Add, ArticleOutlined, Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { COLORS } from '@styles';
import { appContext } from '@utils';
import csvtojson from 'csvtojson';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useParams } from 'react-router-dom';
import { ReactComponent as EmptyNotesAdvisor } from '@assets/svg/EmptyNotesAdvisor.svg';
import { ReactComponent as Backup } from '@assets/svg/Backup.svg';
import { dashedBorder } from '../../utils';

const importClientStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
  } as React.CSSProperties,
  fieldList: {
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
  },
  uploadContainer: {
    borderRadius: '1rem',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    cursor: 'pointer',
    backgroundImage: dashedBorder,
  } as React.CSSProperties,
  uploadIcon: {
    backgroundColor: COLORS.NEUTRAL_DARK_1,
    padding: '1rem',
    borderRadius: '50%',
    fill: COLORS.NEUTRAL_WHITE,
    width: '1.75rem',
    height: '1.75rem',
    marginBottom: '1rem',
  },
  uploadLimitLabel: {
    marginTop: '0.5rem',
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    marginTop: 'auto',
  },
};

const uploadedFileStyles = {
  container: {
    marginTop: '2rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  fileChip: {
    backgroundColor: COLORS.ELEKTRAFI_NEUTRAL,
    color: COLORS.NEUTRAL_WHITE,
    padding: '0.15rem 0.5rem',
    borderRadius: '0.5rem',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    border: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    borderRadius: '0.5rem',
    padding: '1rem',
  } as React.CSSProperties,
  emptyIcon: {
    width: '8rem',
    height: '8rem',
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '1rem',
    padding: '1.5rem 1rem',
    backgroundColor: COLORS.NEUTRAL_DARK_2,
  },
  fileFirstSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  } as React.CSSProperties,
  fileIcon: {
    color: COLORS.ELEKTRAFI_CYAN_NEUTRAL,
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  removeIcon: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
};

const submitStepStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '1rem',
  } as React.CSSProperties,
  icon: {
    fill: `url(#gradient-02)`,
    marginBottom: '1rem',
  },
  waitMessage: {
    textAlign: 'center',
    padding: '0 2rem',
  },
};

interface IProps {
  open: boolean;
  onClose: () => void;
}

export interface UploadedFileSectionProps {
  file: File | null;
  handleRemoveFile: () => void;
}

export function UploadedFileSection({ file, handleRemoveFile }: UploadedFileSectionProps) {
  return (
    <div style={uploadedFileStyles.container}>
      <div style={uploadedFileStyles.header}>
        <Typography variant="h6">Uploaded File</Typography>
        <div style={uploadedFileStyles.fileChip}>{`${!file ? 0 : 1} of 1 File`}</div>
      </div>
      {!file ? (
        <div style={uploadedFileStyles.emptyContainer}>
          <EmptyNotesAdvisor style={uploadedFileStyles.emptyIcon} />
          <Typography variant="subtitle1">No file has been uploaded yet.</Typography>
        </div>
      ) : (
        <div style={uploadedFileStyles.fileContainer}>
          <div style={uploadedFileStyles.fileFirstSection}>
            <div style={uploadedFileStyles.fileName}>
              <ArticleOutlined sx={uploadedFileStyles.fileIcon} />
              <Typography variant="subtitle1">{file.name}</Typography>
            </div>
          </div>
          <Close sx={uploadedFileStyles.removeIcon} onClick={handleRemoveFile} />
        </div>
      )}
    </div>
  );
}

export function SubmittingStep() {
  return (
    <div style={submitStepStyles.container}>
      <svg width={0} height={0}>
        <linearGradient
          id="gradient-02"
          x1={1}
          x2={1}
          y1={0}
          y2={1}
          gradientTransform="rotate(233)"
        >
          <stop offset="0%" stopColor={COLORS.HELP_NEUTRAL} />
          <stop offset="100%" stopColor={COLORS.ELEKTRAFI_NEUTRAL} />
        </linearGradient>
      </svg>
      <Backup style={submitStepStyles.icon} />
      <Typography variant="h5">Importing your document</Typography>
      <Typography variant="body1" color="text.secondary" sx={submitStepStyles.waitMessage}>
        Please wait a moment as we finalize importing your document.
      </Typography>
    </div>
  );
}

const csvFields = [
  { label: 'First and Last Name' },
  { label: 'Company Email' },
  { label: 'Phone Number' },
  { label: 'Permission' },
];

export function ImportClient({ open, onClose }: IProps) {
  const { id } = useParams<any>();
  const [uploadedFile, setFile] = useState<File | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const initialValues = useMemo<{ clients: ClientInput[] }>(() => ({ clients: [] }), []);

  const {
    organizationStore: { addBulkClientToOrg },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ clients }) => {
      setSubmitLoading(true);
      const result = await addBulkClientToOrg(clients, id);
      if (!result) {
        setSubmitLoading(false);
        return;
      }
      await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id)] });
      onClose();
    },
    enableReinitialize: true,
  });

  const handleChange = (file: File) => {
    const fileReader = new FileReader();

    fileReader.readAsText(file);
    fileReader.onload = async (event: ProgressEvent<FileReader>) => {
      const output = event?.target?.result;
      if (output && typeof output === 'string') {
        const result = await csvtojson().fromString(output);
        formik.setFieldValue('clients', result);
      }
    };
    setFile(file);
  };

  const handleRemoveFile = () => {
    setFile(null);
    formik.setFieldValue('clients', []);
  };

  useEffect(() => {
    if (open) {
      setSubmitLoading(false);
      handleRemoveFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CustomDrawer open={open} onClose={onClose} headerLabel="Import Client" headerIcon={<Add />}>
      {!submitLoading ? (
        <div style={importClientStyles.wrapper}>
          <div style={importClientStyles.container}>
            <Typography variant="body1">
              Upload a document containing your clients information.
            </Typography>
            <ul style={importClientStyles.fieldList}>
              {csvFields.map(({ label }) => (
                <li key={label}>
                  Client&apos;s <strong>{label}</strong>{' '}
                </li>
              ))}
            </ul>

            <FileUploader
              handleChange={handleChange}
              name="file"
              types={['CSV']}
              maxSize={1}
              fileOrFiles={uploadedFile}
            >
              <div style={importClientStyles.uploadContainer}>
                <Backup style={importClientStyles.uploadIcon} />
                <Typography variant="subtitle1">Drag and Drop a .CSV file to upload</Typography>
              </div>
              <Typography
                sx={importClientStyles.uploadLimitLabel}
                variant="body2"
                color="text.secondary"
              >
                Maximum file size: <strong>1 MB</strong>
              </Typography>
            </FileUploader>

            <UploadedFileSection file={uploadedFile} handleRemoveFile={handleRemoveFile} />
          </div>
          <div style={importClientStyles.button}>
            <CustomButton
              variant="default"
              onClick={formik.handleSubmit}
              disabled={!uploadedFile}
              isLoading={submitLoading}
            >
              Import Clients
            </CustomButton>
          </div>
        </div>
      ) : (
        <SubmittingStep />
      )}
    </CustomDrawer>
  );
}
