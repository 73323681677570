import React, { useContext, useMemo } from 'react';
import { CustomButton, CustomDrawer } from '@components';
import { ClientRole, ORGANIZATION_QUERY, UpdateClientInput } from '@graphql';
import { BorderColorOutlined, Add } from '@mui/icons-material';
import { Typography, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { COLORS } from '@styles';
import { ReactComponent as DashedDivider } from '@assets/svg/DashedDivider.svg';
import * as Yup from 'yup';

interface IProps {
  client?: any;
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
}

const makeStyles = () => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  editHeader: {
    paddingBottom: '1rem',
    borderBottom: `1px dashed ${COLORS.NEUTRAL_LIGHT_3}`,
  },
  dashedDivider: {
    width: '100%',
  },
  formContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0 1rem',
  } as React.CSSProperties,
  headerClientId: {
    color: COLORS.NEUTRAL_LIGHT_0,
  },
  formItems: {
    margin: '1rem 0',
    borderRadius: '1rem',
  },
  inputBorder: {
    borderRadius: '1rem',
  },
  disabledMenuItem: {
    pointerEvents: 'auto !important',
    cursor: 'not-allowed !important',
  },
  section: {
    margin: '1.5rem 0 0.5rem 0',
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    borderTop: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    marginTop: 'auto',
  },
  fullsizeSpan: { width: '100%' },
});

export function ClientDrawer({ client, open, onClose, isEdit }: IProps) {
  const sx = makeStyles();
  const initialValues = useMemo<UpdateClientInput>(
    () => ({
      id: client?.id || '',
      role: client?.role || ClientRole.Basic,
      phone: client?.phone || '',
      lastName: client?.lastName || '',
      firstName: client?.firstName || '',
      email: client?.email || '',
      workEmail: client?.workEmail || '',
    }),
    [client],
  );

  const { id } = useParams<any>();

  const {
    organizationStore: { updateClientDetail, addClientToOrg },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address.')
      .when([], (_, schema) => (!isEdit ? schema : schema.required('Email is required.'))),
    workEmail: Yup.string()
      .email('Please provide a valid email address.')
      .required('Work email is required.'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
  });

  const formik = useFormik<UpdateClientInput>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      let result;
      if (isEdit) {
        result = await updateClientDetail(values);
      } else {
        const payload = {
          email: values.workEmail,
          role: values.role,
          phone: values.phone,
          firstName: values.firstName,
          lastName: values.lastName,
          org: id,
        };
        result = await addClientToOrg(payload);
      }
      if (result) {
        await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id)] });
        formikHelpers.resetForm();
        onClose();
      }
    },
    enableReinitialize: true,
  });
  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
      headerLabel={isEdit ? 'Edit Client' : 'Add Client'}
      headerIcon={isEdit ? <BorderColorOutlined /> : <Add />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          {isEdit && (
            <>
              <Typography variant="h6">{`${client?.firstName} ${client?.lastName}`}</Typography>
              <Typography sx={sx.headerClientId} variant="subtitle1">
                {client?.id}
              </Typography>
              <DashedDivider style={sx.dashedDivider} />
            </>
          )}
          <Typography sx={sx.section} variant="subtitle2">
            Client Information
          </Typography>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName}
          />

          {isEdit && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Personal Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              InputProps={{ style: sx.inputBorder }}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          )}

          {client?.role !== ClientRole.Spouse && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Work Email"
              name="workEmail"
              onChange={formik.handleChange}
              value={formik.values.workEmail}
              variant="outlined"
              InputProps={{ style: sx.inputBorder }}
              error={!!formik.errors.workEmail}
              helperText={formik.errors.workEmail}
            />
          )}

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone Number"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
          />

          <Typography sx={sx.section} variant="subtitle2">
            Organization Permission
          </Typography>

          <FormControl fullWidth>
            <InputLabel sx={sx.formItems}>Permission</InputLabel>
            <Select
              sx={sx.formItems}
              fullWidth
              name="role"
              onChange={formik.handleChange}
              value={formik.values.role}
              label="Permission"
            >
              <MenuItem value={ClientRole.Admin}>{ClientRole.Admin}</MenuItem>
              <MenuItem value={ClientRole.Basic}>{ClientRole.Basic}</MenuItem>
              <MenuItem value={ClientRole.Manager}>{ClientRole.Manager}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            {isEdit ? 'Save Changes' : 'Add Client'}
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}

ClientDrawer.defaultProps = {
  isEdit: false,
  client: {},
};
