import { CustomButton } from '@components';
import { Close } from '@mui/icons-material';
import { Modal, TextField, Typography } from '@mui/material';
import { COLORS } from '@styles';
import React, { useEffect, useMemo, useState } from 'react';

interface IConfirmationModalProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  confirmationText: string;
  onConfirm: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  variant: 'default' | 'destructive';
  confirmationButtonLabel: string;
}

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: COLORS.NEUTRAL_BLACK,
    borderRadius: '1rem',
    padding: '2rem',
    color: COLORS.NEUTRAL_WHITE,
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  topSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    textAlign: 'center',
  } as React.CSSProperties,
  iconWrapper: {
    padding: '0.75rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapperDefault: {
    backgroundColor: COLORS.ELEKTRAFI_NEUTRAL,
  },
  iconWrapperDestructive: {
    backgroundColor: COLORS.NEGATIVE_NEUTRAL,
  },
  content: {
    textAlign: 'center',
    marginBottom: '1rem',
  } as React.CSSProperties,
  inputBorder: {
    borderRadius: '1rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginTop: '2rem',
  },
};

export function ConfirmationModal({
  icon,
  title,
  children,
  confirmationText,
  onConfirm,
  isOpen,
  onClose,
  variant,
  confirmationButtonLabel,
}: IConfirmationModalProps) {
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const iconWrapper = useMemo(() => {
    const iconColor =
      variant === 'default' ? styles.iconWrapperDefault : styles.iconWrapperDestructive;
    return {
      ...iconColor,
      ...styles.iconWrapper,
    };
  }, [variant]);

  useEffect(() => {
    if (isOpen) {
      setText('');
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    if (confirmationText === text && typeof onConfirm === 'function') {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={styles.container}>
        <div style={styles.topSection}>
          <Close style={styles.closeIcon} onClick={onClose} />
        </div>
        <div style={styles.header}>
          <div style={iconWrapper}>{icon}</div>
          <Typography variant="h4">{title}</Typography>
        </div>
        <div style={styles.content}>{children}</div>
        <TextField
          fullWidth
          name="confirmationText"
          onChange={(e) => setText(e.target.value)}
          value={text}
          variant="outlined"
          required
          placeholder={confirmationText}
          InputProps={{ style: styles.inputBorder }}
        />
        <div style={styles.footer}>
          <CustomButton variant="transparent" onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton
            variant={variant}
            onClick={handleSubmit}
            disabled={confirmationText !== text}
            isLoading={isLoading}
          >
            {confirmationButtonLabel}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
