import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
} from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import { appContext } from '@utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';

const makeStyles = () => ({
    pageContainer: {
        height: '100%',
    },
    formItems: {
        margin: '1rem 0',
    },
    formIcons: {
        cursor: 'pointer',
    },
    accordianPaper: { padding: '1rem', margin: '1rem 0', textAlign: 'end' },
});

export const RetirementSettingsOld = observer(() => {
    const sx = makeStyles();
    const {
        userStore: { settings, getCurrentSession },
        settingsStore: { updateSettings },
    } = useContext(appContext);
    return (
        <Formik
            initialValues={settings}
            enableReinitialize
            onSubmit={async (values) =>
                (await updateSettings(values)) &&
                (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
            }
        >
            {(formik) => (
                <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
                    <Grid sx={sx.pageContainer} container
                          alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Inflation Rate"
                                        name="retirement.inflationRate"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.retirement?.inflationRate|| ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Appreciation Rate"
                                        name="retirement.appreciationRate"
                                        onChange={formik.handleChange}
                                        value={formik.values.
                                            retirement?.appreciationRate|| ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />


                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Income Required At Retirement"
                                        name=
                                        "retirement.incomeRequiredAtRetirement"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                            retirement?.incomeRequiredAtRetirement || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label=
                                            "Defined Contribution Max"
                                        name=
                                        "retirement.definedContributionMaxLimit"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                            retirement?.definedContributionMaxLimit || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />


                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label=
                                        "Group RRSP Contribution Max"
                                        name=
                                        "retirement.groupRrspContributionMaxLimit"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                            retirement?.groupRrspContributionMaxLimit || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="RRSP Contribution Max"
                                        name=
                                        "retirement.rrspContributionMaxLimit"
                                        onChange={formik.handleChange}
                                        value={formik.values.
                                            retirement?.rrspContributionMaxLimit || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="TFSA Contribution Max"
                                name=
                                "retirement.tfsaContributionMaxLimit"
                                onChange={formik.handleChange}
                                value={formik.values.
                                    retirement?.tfsaContributionMaxLimit || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="Max CPP Payout"
                                name=
                                "retirement.maxCppPayout"
                                onChange={formik.handleChange}
                                value={formik.
                                    values.retirement?.maxCppPayout|| ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label=
                                "Max OAS Pauout"
                                name="retirement.maxOasPayout"
                                onChange=
                                {formik.handleChange}
                                value={formik.values.
                                    retirement?.maxOasPayout || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label=
                                    "OAS Starting Age"
                                name="retirement.oasStartingAge"
                                onChange=
                                    {formik.handleChange}
                                value={formik.values.
                                    retirement?.oasStartingAge || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button fullWidth variant="contained"
                            disabled={formik.isSubmitting} type="submit">
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
});
