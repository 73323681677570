import { useContext } from 'react';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import { UserType } from '@graphql';
import Box from '@mui/material/Box';
import { Cover, CustomButton, Input } from '@components';
import { Link } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Avatar from '@mui/material/Avatar';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';

const StyledAvatar = styled(Avatar)`
  background-color: #eef0f6;
`;

const makeStyles = () => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    overflow: 'hidden',
  },
  formContainer: {
    backgroundColor: 'white',
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
  },
  formContent: {
    margin: '3.5rem auto',
    width: '24.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  backToLogin: {
    width: '100%',
    margin: '0 1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    '& a': {
      textDecoration: 'none',
      display: 'inherit',
      alignItems: 'inherit',
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      gap: 'inherit',
      fontWeight: 600,
      lineHeight: '1rem',
      color: '#232A43',
    },
  },

  formTitle: {
    fontFamily: 'Urbanist',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    color: '#232A43',
  },

  formInfo: {
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    color: '#445488',
  },
});
export function ForgotPasswordPage() {
  const sx = makeStyles();
  const {
    userStore: { resetPasswordRequest },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: { email: '', type: UserType.SuperAdmin },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      await resetPasswordRequest(values);
    },
  });

  return (
    <Box sx={sx.pageContainer}>
      {/* Left side */}
      <Cover />
      {/* Right side */}
      <Box sx={sx.formContainer}>
        <Box sx={sx.backToLogin}>
          <Link to="/login">
            <ArrowBackIosOutlinedIcon />
            Back to login
          </Link>
        </Box>
        <Box component="form" onSubmit={formik.handleSubmit} sx={sx.formContent}>
          <Box display="inline-flex" gap="1rem" alignItems="center" sx={sx.formTitle}>
            <StyledAvatar>🔑</StyledAvatar>
            Reset your password
          </Box>
          <Typography variant="subtitle1" sx={sx.formInfo}>
            Enter your registered email address associated with your account. We&apos;ll send you an
            email with instructions to reset your password.
          </Typography>
          <Box>
            <Input
              value={formik.values.email}
              inputId="email-input"
              label="Email"
              name="email"
              onChange={formik.handleChange}
            />
          </Box>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Continue
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
