import React, { useContext, useMemo } from 'react';
import { Typography, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useFormik } from 'formik';
import { SuperAdmin, SuperAdminRole, UpdateManagerInput } from '@graphql';
import { Add, BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import { ReactComponent as DashedDivider } from '@assets/svg/DashedDivider.svg';
import { COLORS } from '@styles';
import * as Yup from 'yup';

const makeStyles = () => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  editHeader: {
    paddingBottom: '1rem',
    borderBottom: `1px dashed ${COLORS.NEUTRAL_LIGHT_3}`,
  },
  dashedDivider: {
    width: '100%',
  },
  formContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0 1rem',
  } as React.CSSProperties,
  headerClientId: {
    color: COLORS.NEUTRAL_LIGHT_0,
  },
  formItems: {
    margin: '1rem 0',
    borderRadius: '1rem',
  },
  inputBorder: {
    borderRadius: '1rem',
  },
  section: {
    margin: '1.5rem 0 0.5rem 0',
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    borderTop: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    marginTop: 'auto',
  },
});

interface IManagerProps {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  admin?: SuperAdmin | null;
}

type TManagerInput = UpdateManagerInput & { email: string };

export function ManagerDrawer({ open, handleClose, isEdit, admin }: IManagerProps) {
  const sx = makeStyles();
  const initialValues = useMemo<TManagerInput>(
    () => ({
      id: admin?.id || '',
      name: admin?.name || '',
      email: '',
      role: admin?.adminRole || SuperAdminRole.Manager,
    }),
    [admin],
  );

  const {
    managersStore: { updateManager, fetchAllManagers, createManager },
  } = useContext(appContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address.')
      .when([], (_, schema) => (isEdit ? schema : schema.required('Email is required.'))),
    name: Yup.string().required('Name is required.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      let result;

      if (isEdit) {
        result = await updateManager(values);
      } else {
        const payload = {
          email: values.email,
          name: values.name,
        };

        result = await createManager(payload);
      }

      if (result) {
        await fetchAllManagers();
        formikHelpers.resetForm();
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel={isEdit ? 'Edit Manager' : 'Add Manager'}
      headerIcon={isEdit ? <BorderColorOutlined /> : <Add />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          {isEdit && (
            <>
              <Typography variant="h6">{admin?.name}</Typography>
              <Typography sx={sx.headerClientId} variant="subtitle1">
                {admin?.id}
              </Typography>
              <DashedDivider style={sx.dashedDivider} />
            </>
          )}
          <Typography sx={sx.section} variant="subtitle2">
            Manager Information
          </Typography>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
            required
            InputProps={{ style: sx.inputBorder }}
          />

          {!isEdit && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              type="email"
              required
              InputProps={{
                style: sx.inputBorder,
              }}
            />
          )}

          {isEdit && (
            <FormControl fullWidth>
              <InputLabel sx={sx.formItems}>Role</InputLabel>
              <Select
                sx={sx.formItems}
                fullWidth
                name="role"
                onChange={formik.handleChange}
                value={formik.values.role}
                label="Role"
              >
                <MenuItem value={' '} disabled>
                  Select Role
                </MenuItem>
                <MenuItem value={SuperAdminRole.Admin}>Admin</MenuItem>
                <MenuItem value={SuperAdminRole.Manager}>Manager</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            {isEdit ? 'Save Changes' : 'Add Manager'}
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}

ManagerDrawer.defaultProps = {
  isEdit: false,
  admin: {},
};
