import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Fragment } from 'react';

interface SectionProps {
  title: string;
  onEditClick: () => void;
  items: {
    key: string;
    value: string;
  }[];
}

type SettingsContainerProps = {
  title: string;
  sections: SectionProps[];
  children?: React.ReactNode;
};

const makeSectionStyles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',

    fontFamily: `Inter`,
    fontSize: `0.875rem`,
    fontStyle: `normal`,
    fontWeight: 400,
    lineHeight: `1rem`,
    color: `#DDE1EE`,

    '& h3': {
      margin: 0,
      fontWeight: 600,
      fontSize: `0.875rem`,
    },
  },
  iconButton: {
    margin: 0,
    padding: 0,
  },
  icon: {
    color: `#5FC6E3`,
  },
});

const makeSettingsStyles = () => ({
  root: {
    width: '100%',
    maxWidth: `42rem`,
    padding: `2rem`,
    gap: `1.5rem`,
    margin: `0 auto`,

    // Border details
    borderRadius: `1rem`,
    border: `1px solid #2F2F2F`,
    color: `white`,

    '& hr': {
      backgroundColor: `#2F2F2F`,
    },
  },
  title: {
    fontFamily: `Urbanist`,
    fontSize: `1.25rem`,
    fontStyle: `normal`,
    fontWeight: 700,
  },
});

function Section({ title, onEditClick, items }: SectionProps) {
  const sx = makeSectionStyles();

  return (
    <Box>
      <Box sx={sx.row}>
        <Typography variant="h3">{title}</Typography>
        <IconButton onClick={onEditClick} sx={sx.iconButton}>
          <BorderColorOutlinedIcon sx={sx.icon} />
        </IconButton>
      </Box>
      {items.map((item) => (
        <Box key={item.key} sx={sx.row}>
          <p>{item.key}</p>
          <p>{item.value}</p>
        </Box>
      ))}
    </Box>
  );
}

export function SettingsContainer({ title, sections, children = null }: SettingsContainerProps) {
  const sx = makeSettingsStyles();

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem" sx={sx.root}>
      <Typography variant="h2" style={sx.title}>
        {title}
      </Typography>
      <Divider />
      {sections.map((section) => (
        <Fragment key={section.title}>
          <Section {...section} />
          {sections.indexOf(section) !== sections.length - 1 && <Divider />}
        </Fragment>
      ))}
      {children}
    </Box>
  );
}

SettingsContainer.defaultProps = {
  children: null,
};
