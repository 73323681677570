import React from 'react';
import Box from '@mui/system/Box';
import BG from '@assets/svg/BG.svg';
import LoginBanner from '@assets/svg/LoginBanner.svg';
import Typography from '@mui/material/Typography';
import Logo from '@assets/img/logo.png';

const makeStyles = () => ({
  coverContainer: {
    width: '50%',
    height: '100%',
    backgroundColor: `#111522`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  coverText: {
    color: 'white',
    fontFamily: 'Urbanist',
    fontSize: '3rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '3.5rem',
    padding: '0 5rem',
  },
  coverBanner: {
    display: 'block',
    margin: 'auto',
    marginTop: '4rem',
    width: `28.875rem`,
    height: `28.875rem`,
  },
  logo: {
    maxWidth: '10rem',
    padding: '2rem 4rem 0',
  },
});

export function Cover() {
  const sx = makeStyles();
  return (
    <Box
      sx={sx.coverContainer}
      style={{
        backgroundImage: `url(${BG})`,
      }}
    >
      <img src={Logo} alt="logo" style={sx.logo} />
      <Typography variant="h1" sx={sx.coverText}>
        Building brighter <br />
        futures with <br />
        financial wellness
      </Typography>
      <img src={LoginBanner} alt="banner" style={sx.coverBanner} />
    </Box>
  );
}
