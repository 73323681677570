import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { appContext } from '@utils';
import { ALL_ADVISORS_QUERY, Advisor, SuperAdminRole } from '@graphql';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DeleteOutlined } from '@assets/svg/DeleteOutlined.svg';
import { ConfirmationModal } from '@components';
import { AdvisorsDrawer } from '../AdvisorsDrawer';

interface AdvisorsCustomToolbarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

function CustomToolbar({ searchQuery, setSearchQuery }: AdvisorsCustomToolbarProps) {
  const [open, setOpen] = useState<boolean>(false);

  const {
    userStore: { user },
  } = useContext(appContext);
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <div style={{ display: `flex`, alignItems: `center` }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoFocus
          style={{ marginRight: 16, width: `23rem` }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        {user.adminRole === SuperAdminRole.Admin && (
          <Button variant="contained" onClick={handleClose}>
            Create Advisor
          </Button>
        )}
      </div>
      <AdvisorsDrawer open={open} handleClose={handleClose} />
    </GridToolbarContainer>
  );
}

export const AllAdvisorsTable = observer(() => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [advisor, setAdvisor] = useState<Advisor | null>(null);
  const {
    userStore: { allAdvisors, getCurrentSession, sessionResponse },
    advisorStore: { deleteAdvisor },
  } = useContext(appContext);
  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
  }, [getCurrentSession]);

  const handleEditModal = useCallback(() => {
    setOpenEdit(!openEdit);
  }, [openEdit]);

  const handleDeleteModal = useCallback(() => {
    setOpenDelete(!openDelete);
  }, [openDelete]);

  const handleDeleteAdvisor = async () => {
    if (!advisor) {
      return;
    }

    const result = await deleteAdvisor({ id: advisor?.id });

    if (result) {
      await getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
      handleDeleteModal();
    }
  };

  const isAdmin = useContext(appContext).userStore.user.adminRole === SuperAdminRole.Admin;

  const columnsWithActions = React.useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'firstName', headerName: 'First Name', disableColumnMenu: true, flex: 1 },
      { field: 'lastName', headerName: 'Last Name', disableColumnMenu: true, flex: 1 },
      { field: 'phone', headerName: 'Phone', disableColumnMenu: true, flex: 1 },
      { field: 'company', headerName: 'Company', disableColumnMenu: true, flex: 1 },
      { field: 'title', headerName: 'Title', disableColumnMenu: true, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Advisor"
            onClick={() => {
              setAdvisor(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Advisor"
            onClick={() => {
              setAdvisor(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [handleDeleteModal, handleEditModal],
  );

  const columns = isAdmin
      ? columnsWithActions
      : columnsWithActions.filter((column) => column.field !== 'actions');

  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredAdvisors = useMemo(
    () =>
      allAdvisors.filter((a: Advisor) =>
        ['email', 'firstName', 'lastName', 'phone', 'company', 'title'].some(
          (key) => a && a[key as keyof Advisor]?.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      ),
    [searchQuery, allAdvisors],
  );

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={filteredAdvisors}
        columns={columns}
        isRowSelectable={() => false}
        loading={sessionResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: { searchQuery, setSearchQuery },
        }}
      />
      <AdvisorsDrawer open={openEdit} handleClose={handleEditModal} isEdit advisor={advisor} />
      <ConfirmationModal
        icon={<DeleteOutlined />}
        title={`Are you sure you want to remove ${advisor?.firstName} ${advisor?.lastName} from ElektraFi platform?`}
        onClose={handleDeleteModal}
        isOpen={openDelete}
        variant="destructive"
        confirmationText={`${advisor?.firstName} ${advisor?.lastName}`}
        onConfirm={handleDeleteAdvisor}
        confirmationButtonLabel="Remove Advisor"
      >
        <p>Please confirm if you would like to remove access to ElektraFi for this Advisor.</p>
        <p>To proceed with the deletion, please type the advisor&apos;s name:</p>
        <strong>{`${advisor?.firstName} ${advisor?.lastName}`}</strong>
      </ConfirmationModal>
    </div>
  );
});
