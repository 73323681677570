import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import { globalSettingsStyles } from '../../utils';

interface IInsuranceDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
  isLifeInsurance?: boolean;
}

export function InsuranceDrawer({
  open,
  handleClose,
  settings,
  isLifeInsurance,
}: IInsuranceDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel={isLifeInsurance ? 'Edit Life Insurance' : 'Edit Disability Insurance'}
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          {isLifeInsurance ? (
            <>
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Children Benefits"
                name="insurance.life.childrensBenefits"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.childrensBenefits || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="SB Under 65"
                name="insurance.life.survivorBenefitsUnder65"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.survivorBenefitsUnder65 || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="SB Above 65"
                name="insurance.life.survivorBenefitsAbove65"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.survivorBenefitsAbove65 || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Income Percent To Replace"
                name="insurance.life.incomePercentToReplace"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.incomePercentToReplace || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Inflation Rate"
                name="insurance.life.inflationRate"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.inflationRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="Funeral Expenses"
                name="insurance.life.funeralExpenses"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.funeralExpenses || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <MoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="CPP Payout"
                name="insurance.life.canadaPensionPlanPayout"
                onChange={formik.handleChange}
                value={formik.values.insurance?.life?.canadaPensionPlanPayout || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  sx: sx.inputBorder,
                  startAdornment: (
                    <InputAdornment position="start" sx={sx.inputIcon}>
                      <MoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Income Coverage"
              name="insurance.disability.incomeCoveragePercent"
              onChange={formik.handleChange}
              value={formik.values.insurance?.disability?.incomeCoveragePercent || ''}
              variant="outlined"
              type="number"
              required
              InputProps={{
                sx: sx.inputBorder,
                startAdornment: (
                  <InputAdornment position="start" sx={sx.inputIcon}>
                    <PercentIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}

InsuranceDrawer.defaultProps = {
  isLifeInsurance: false,
};
