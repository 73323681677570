/* eslint-disable react/require-default-props */
import { InputBase as MUIInputBase } from '@mui/material';
import Box from '@mui/system/Box';
import styled from '@mui/system/styled';

const InputBase = styled(MUIInputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: '1rem',
    border: '1px solid #DDE1EE',
    backgroundColor: 'white',
    padding: '4px 16px',
    height: '38px',

    fontFamily: 'Inter',
    fontSize: 16,
    color: '#929EC7',
    fontWeight: 400,
    lineHeight: '24px',
  },
}));

interface InputProps {
  value: string;
  name: string;
  inputId: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const makeStyles = () => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    alignItems: 'flex-start',
  },
  labelDefault: {
    color: '#232A43',
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    paddingLeft: '0.75rem',
  },
});

export function Input({ value, inputId, onChange, label, name }: InputProps) {
  const sx = makeStyles();
  return (
    <Box sx={sx.inputContainer}>
      {label && (
        <label htmlFor={inputId} style={sx.labelDefault}>
          {label}
        </label>
      )}
      <InputBase
        id={inputId}
        value={value}
        onChange={onChange}
        placeholder={label}
        name={name}
        fullWidth
      />
    </Box>
  );
}
