import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Divider,
  IconButton,
} from '@mui/material';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY, TaxSlab } from '@graphql';
import { SettingsContainer } from '@components';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { v4 as uuidv4 } from 'uuid';
import { TaxOverviewDrawer } from './TaxOverviewDrawer';
import { PROVINCES, ProvincesEnum } from '../../utils';
import { TaxProvinceDrawer } from './TaxProvinceDrawer';

const makeProvincesViewStyles = () => ({
  container: {
    color: `#DDE1EE`,
    fontSize: `0.875rem`,
    fontFamily: `Inter`,
    fontStyle: `normal`,
    fontWeight: 400,
    lineHeight: `1rem`,

    '& h6': {
      margin: 0,
      fontWeight: 600,
      fontSize: `0.875rem`,
    },
  },
  iconButton: {
    margin: 0,
    padding: 0,
  },
  formItems: {
    borderRadius: `1rem`,
  },
});

function ProvincesView() {
  const [isTaxProvinceOpen, setIsTaxProvinceOpen] = React.useState(false);
  const {
    userStore: { settings },
  } = useContext(appContext);
  const sx = makeProvincesViewStyles();
  const [selectedProvince, setSelectedProvince] = useState<keyof typeof settings.tax>('federal');

  const onTaxOverviewClick = () => setIsTaxProvinceOpen(true);

  return (
    <Box display="flex" flexDirection="column" gap="1rem" sx={sx.container}>
      {/* Province Selector */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <FormControl fullWidth>
          <InputLabel>Tax Location</InputLabel>
          <Select
            sx={sx.formItems}
            fullWidth
            name="role"
            value={selectedProvince}
            onChange={(e) => setSelectedProvince(e.target.value as keyof typeof settings.tax)}
            label="Tax Location"
          >
            {PROVINCES.map((province) => (
              <MenuItem key={`${province.name}-menu-item`} value={province.value}>
                {province.name}
              </MenuItem>
            )) || []}
          </Select>
        </FormControl>
      </Box>
      {/* Province View */}
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="3rem"
          width="100%"
        >
          <Typography variant="h6">Overview</Typography>
          <IconButton sx={sx.iconButton} onClick={onTaxOverviewClick}>
            <BorderColorOutlinedIcon sx={{ color: `#5FC6E3` }} />
          </IconButton>
        </Box>
        {(Array.isArray(settings?.tax?.[selectedProvince]) &&
          (settings?.tax?.[selectedProvince] as TaxSlab[])?.map((taxSlab: TaxSlab, index) => {
            const isLast = index === (settings?.tax?.[selectedProvince] as TaxSlab[]).length - 1;
            return (
              <Box
                key={uuidv4()}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                height="3rem"
                gap="2rem"
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography>Limit</Typography>
                  <Typography>{isLast ? 'Infinity' : taxSlab.limit}</Typography>
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography>Rate</Typography>
                  <Typography>{taxSlab.rate}%</Typography>
                </Box>
              </Box>
            );
          })) ||
          []}
      </Box>
      <TaxProvinceDrawer
        open={isTaxProvinceOpen}
        handleClose={() => setIsTaxProvinceOpen(false)}
        selectedProvince={selectedProvince as ProvincesEnum}
        settings={settings}
      />
    </Box>
  );
}

export const TaxSettings = observer(() => {
  const [isTaxOverviewOpen, setIsTaxOverviewOpen] = React.useState(false);
  const {
    userStore: { settings, getCurrentSession },
  } = useContext(appContext);
  useEffect(() => {
    getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
  }, [getCurrentSession]);

  const sections = {
    title: 'Overview',
    onEditClick: () => setIsTaxOverviewOpen(true),
    items: [
      { key: 'CPP Income Threshold', value: settings.tax?.cppIncomeThreshold?.toString() || '' },
      { key: 'CPP Rate', value: settings.tax?.cppCredit.contributionRate?.toString() || '' },
      { key: 'CPP Threshold', value: settings.tax?.cppCredit.threshold?.toString() || '' },
      { key: 'EI Rate', value: settings.tax?.eiCredit.contributionRate?.toString() || '' },
      { key: 'EI Threshold', value: settings.tax?.eiCredit.threshold?.toString() || '' },
      { key: 'Personal Credit', value: settings.tax?.personalCredit?.toString() || '' },
      { key: 'Refundable Tax Claim', value: settings.tax?.refundableTaxClaim?.toString() || '' },
    ],
  };

  return (
    <>
      <SettingsContainer title="Tax Settings" sections={[sections]}>
        <ProvincesView />
      </SettingsContainer>

      <TaxOverviewDrawer
        open={isTaxOverviewOpen}
        handleClose={() => setIsTaxOverviewOpen(false)}
        settings={settings}
      />
    </>
  );
});
