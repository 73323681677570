import React, { useContext, useEffect, useState } from 'react';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { Cover, CustomButton, PasswordInput } from '@components';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const StyledAvatar = styled(Avatar)`
  background-color: #eef0f6;
`;

const makeStyles = () => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    overflow: 'hidden',
  },
  formContainer: {
    backgroundColor: 'white',
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
  },

  formContent: {
    margin: '3.5rem auto',
    width: '25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },

  formTitle: {
    fontFamily: 'Urbanist',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    color: '#232A43',
    wordBreak: 'break-word',
  },

  formInfo: {
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    color: '#445488',
  },

  ruleSuccess: {
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    color: '#079243',
  },

  ruleMissing: {
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    color: '#929EC7',
  },
});

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(`Password is required`)
    .min(12, `Password must be at least 12 characters`)
    .matches(/[A-Z]/, `Password must have at least one uppercase letter`)
    .matches(/[a-z]/, `Password must have at least one lowercase letter`)
    .matches(/[0-9]/, `Password must have at least one number`)
    .matches(/[\W_]/, `Password must have at least one special character`),
  confirmPassword: Yup.string()
    .required(`Confirm password is required`)
    .test(
      `passwords-match`,
      `Passwords must match`,
      (value, { parent }) => value === parent.password,
    ),
});

export function ResetPasswordPage() {
  const sx = makeStyles();
  const {
    userStore: { resetPassword },
  } = useContext(appContext);

  const { tokenId, userId } = useParams<any>();

  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '', tokenId, userId },
    validationSchema,
    onSubmit: async (values) => {
      await resetPassword(values);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [atLeast12, setAtLeast12] = useState(false);
  const [upperAndLowerCase, setUpperAndLowerCase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecial, setOneSpecial] = useState(false);

  const rules = [
    { text: `At Least 12 characters`, state: atLeast12, key: `atLeast12` },
    {
      text: `Uppercase and lowercase characters`,
      state: upperAndLowerCase,
      key: `upperAndLowerCase`,
    },
    { text: `One number`, state: oneNumber, key: `oneNumber` },
    { text: `One special case character`, state: oneSpecial, key: `oneSpecial` },
  ];

  useEffect(() => {
    const updatePasswordRules = () => {
      setAtLeast12(formik.values.password.length >= 12);
      setUpperAndLowerCase(
        /[a-z]/.test(formik.values.password) && /[A-Z]/.test(formik.values.password),
      );
      setOneNumber(/\d/.test(formik.values.password));
      setOneSpecial(/\W|_/.test(formik.values.password));
    };

    updatePasswordRules();
  }, [formik.values.password]);

  return (
    <Box sx={sx.pageContainer}>
      {/* Left side */}
      <Cover />
      {/* Right side */}
      <Box sx={sx.formContainer}>
        <Box component="form" onSubmit={formik.handleSubmit} sx={sx.formContent}>
          <Box display="inline-flex" gap="1rem" alignItems="center" sx={sx.formTitle}>
            <StyledAvatar>🔑</StyledAvatar>
            Set your new password
          </Box>
          <Typography variant="subtitle1" sx={sx.formInfo}>
            Choose a strong and unique password. Remember to include a combination of:
          </Typography>

          <Box display="flex" flexDirection="column" gap="0.25rem" width="100%">
            {rules.map((rule) => (
              <Box key={`rule-${rule.key}`} display="flex" gap="0.5rem" alignItems="center">
                {rule.state ? (
                  <CheckIcon style={{ color: `#079243`, fontSize: `1rem` }} />
                ) : (
                  <CloseIcon style={{ color: `#929EC7`, fontSize: `1rem` }} />
                )}
                <Typography sx={rule.state ? sx.ruleSuccess : sx.ruleMissing}>
                  {rule.text}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box>
            <PasswordInput
              label="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              inputId="password-input"
            />
          </Box>
          <Box>
            <PasswordInput
              label="Confirm Password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              inputId="confirm-password-input"
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <Typography variant="subtitle2" color="error">
                {formik.errors.confirmPassword}
              </Typography>
            )}
          </Box>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Continue
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
