import React, { useContext } from 'react';
import { TextField, InputAdornment, Typography } from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY, TaxSlab } from '@graphql';
import { Add, BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import { ReactComponent as DeleteOutlinedIcon } from '@assets/svg/DeleteOutlined.svg';
import { COLORS } from '@styles';
import { globalSettingsStyles, ProvincesEnum } from '../../utils';

interface ITaxProvinceDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
  selectedProvince: ProvincesEnum;
}

const taxProvinceStyles = {
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1rem',
  } as React.CSSProperties,
  cardContainer: {
    border: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    borderRadius: '1rem',
    padding: '1rem',
    marginBottom: '2rem',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteIcon: {
    fill: COLORS.NEGATIVE_NEUTRAL,
    height: '1.5rem',
    cursor: 'pointer',
  },
  addTaxAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

interface ITaxProvinceCardProps {
  index: number;
  arrayHelpers: any;
  formik: any;
  selectedProvince: ProvincesEnum;
  taxSlab: TaxSlab;
  sx: any;
}

export function TaxProvinceCard({
  index,
  arrayHelpers,
  formik,
  selectedProvince,
  taxSlab,
  sx,
}: ITaxProvinceCardProps) {
  const isLast = index === formik.values.tax[selectedProvince].length - 1;
  const isSecondLast = index === formik.values.tax[selectedProvince].length - 2;

  return (
    <div style={taxProvinceStyles.cardContainer}>
      <div style={taxProvinceStyles.cardHeader}>
        <Typography variant="body1">Tax Bracket</Typography>
        {index !== 0 && (
          <DeleteOutlinedIcon
            style={taxProvinceStyles.deleteIcon}
            onClick={() => arrayHelpers.remove(index)}
          />
        )}
      </div>

      <TextField
        sx={sx.formItems}
        fullWidth
        label="Up to income Limit"
        name={`tax.${selectedProvince}.${index}.limit`}
        onChange={(e) => {
          if (isSecondLast) {
            formik.setFieldValue(
              `tax.${selectedProvince}.${index + 1}.limit`,
              parseInt(e.currentTarget.value, 10),
            );
          }
          if (!isLast) formik.handleChange(e);
        }}
        disabled={isLast}
        value={(isLast && 'Infinity') || taxSlab.limit}
        variant="outlined"
        type={(isLast && 'text') || 'number'}
        required
        InputProps={{
          sx: sx.inputBorder,
          startAdornment: (
            <InputAdornment position="start">
              <MoneyIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={sx.formItems}
        fullWidth
        label="Rate"
        name={`tax.${selectedProvince}.${index}.rate`}
        onChange={formik.handleChange}
        value={taxSlab.rate}
        variant="outlined"
        type="number"
        required
        InputProps={{
          sx: sx.inputBorder,
          startAdornment: (
            <InputAdornment position="start">
              <PercentIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export function TaxProvinceDrawer({
  open,
  handleClose,
  settings,
  selectedProvince,
}: ITaxProvinceDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  const province = formik.values.tax?.[selectedProvince as unknown as keyof typeof ProvincesEnum];

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel={`Edit ${
        ProvincesEnum[selectedProvince as unknown as keyof typeof ProvincesEnum]
      } Tax Brackets`}
      headerIcon={<BorderColorOutlined />}
    >
      <FormikProvider value={formik}>
        <FieldArray
          name={`tax.${selectedProvince}`}
          validateOnChange={false}
          render={(arrayHelpers) => (
            <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
              <div style={sx.formContainer}>
                <Typography sx={sx.section} variant="subtitle2">
                  Organization Information
                </Typography>
                {province?.map((taxSlab: TaxSlab, index: number) => (
                  <TaxProvinceCard
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tax-province-${index}`}
                    index={index}
                    arrayHelpers={arrayHelpers}
                    formik={formik}
                    selectedProvince={selectedProvince}
                    taxSlab={taxSlab}
                    sx={sx}
                  />
                ))}
              </div>
              <div style={sx.button}>
                <div style={taxProvinceStyles.footer}>
                  <CustomButton
                    variant="transparent"
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        limit: province?.slice(-1)?.[0]?.limit || 0,
                        rate: 0,
                      } as TaxSlab)
                    }
                  >
                    <div style={taxProvinceStyles.addTaxAction}>
                      <Add />
                      <span>Add Tax Bracket</span>
                    </div>
                  </CustomButton>
                  <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
                    Save Changes
                  </CustomButton>
                </div>
              </div>
            </form>
          )}
        />
      </FormikProvider>
    </CustomDrawer>
  );
}
