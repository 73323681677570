export enum PlatformFeatures {
  FINANCIAL_WELLNESS = 'financialWellness',
  TCR = 'tcr',
}

export const platformFeaturesLabels = {
  [PlatformFeatures.FINANCIAL_WELLNESS]: 'Financial Wellness',
  [PlatformFeatures.TCR]: 'Total Compensation & Rewards',
};

export const platformFeatureOptions = [
  {
    label: 'Total Compensation & Rewards',
    value: PlatformFeatures.TCR,
  },
  {
    label: 'Financial Wellness',
    value: PlatformFeatures.FINANCIAL_WELLNESS,
  },
];

export const organizationStatusOptions = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const dashedBorder = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23445488' stroke-width='2' stroke-dasharray='20%2c 16%2c 16' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e")`;

export enum CreateOrganizationSteps {
  ORGANIZATION_DETAILS = 'organizationDetails',
  ADMIN_DETAILS = 'adminDetails',
}

export enum OrganizationUpdateEnum {
  UPDATE = 'update',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}