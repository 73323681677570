import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
} from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { appContext } from '@utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';

const makeStyles = () => ({
    pageContainer: {
        height: '100%',
    },
    formItems: {
        margin: '1rem 0',
    },
    formIcons: {
        cursor: 'pointer',
    },
    accordianPaper: { padding: '1rem', margin: '1rem 0', textAlign: 'end' },
});

interface IProps {
    name: string;
    children: React.ReactNode;
}

function TaxAccordian({ name, children }: IProps) {
    return (
        <Accordion sx={{ margin: '1rem 0' }} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{ color: 'text.primary' }}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: 'center' }}>{children}</AccordionDetails>
        </Accordion>
    );
}

export const DebtSettingsOld = observer(() => {
    const sx = makeStyles();
    const {
        userStore: { settings, getCurrentSession },
        settingsStore: { updateSettings },
    } = useContext(appContext);
    return (
        <Formik
            initialValues={settings}
            enableReinitialize
            onSubmit={async (values) =>
                (await updateSettings(values)) &&
                (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
            }
        >
            {(formik) => (
                <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
                    <Grid sx={sx.pageContainer} container
                          alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TaxAccordian name="Refinancing Without Mortgage">
                                <Paper sx={sx.accordianPaper} elevation={2}>
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Interest Rate"
                                        name="debt.refinancingWithoutMortgage.interestRate"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.debt?.
                                            refinancingWithoutMortgage?.interestRate || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Total Years To Pay Debt"
                                        name="debt.refinancingWithoutMortgage.totalYearsToPayDebt"
                                        onChange={formik.handleChange}
                                        value={formik.values.
                                            debt?.
                                            refinancingWithoutMortgage?.totalYearsToPayDebt || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Paper>
                            </TaxAccordian>
                            <TaxAccordian name="Refinancing Mortgage">
                                <Paper sx={sx.accordianPaper} elevation={2}>
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Interest Rate"
                                        name=
                                        "debt.refinancingMortgage.interestRate"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                            debt?.refinancingMortgage?.interestRate || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label=
                                        "Total Years To Pay Debt"
                                        name=
                                        "debt.refinancingMortgage.totalYearsToPayDebt"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                        debt?.refinancingMortgage?.totalYearsToPayDebt || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Paper>
                            </TaxAccordian>
                            <TaxAccordian name="Refinancing All Debts">
                                <Paper sx={sx.accordianPaper} elevation={2}>
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label=
                                        "Interest Rate"
                                        name=
                                        "debt.refinancingByAllDebts.interestRate"
                                        onChange=
                                        {formik.handleChange}
                                        value={formik.values.
                                        debt?.refinancingByAllDebts?.interestRate || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={sx.formItems}
                                        fullWidth
                                        label="Total Years To Pay Debt"
                                        name=
                                        "debt.refinancingByAllDebts.totalYearsToPayDebt"
                                        onChange={formik.handleChange}
                                        value={formik.values.
                                        debt?.refinancingByAllDebts?.totalYearsToPayDebt || ''}
                                        variant="outlined"
                                        type="number"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Paper>
                            </TaxAccordian>
                            <TaxAccordian name="Mortgage Qualifier">
                                <Paper sx={sx.accordianPaper} elevation={2}>
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="Down Payment"
                                name=
                                "debt.mortgageQualification.downPayment"
                                onChange={formik.handleChange}
                                value={formik.values.debt?.mortgageQualification?.downPayment || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="Total years To Pay Debt"
                                name=
                                "debt.mortgageQualification.totalYearsToPayDebt"
                                onChange={formik.handleChange}
                                value={formik.values
                                .debt?.mortgageQualification?.totalYearsToPayDebt || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label=
                                "Interest Rate"
                                name="debt.mortgageQualification.interestRate"
                                onChange=
                                {formik.handleChange}
                                value={formik.values.
                                debt?.mortgageQualification?.interestRate || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="Property Insurance"
                                name=
                                "debt.mortgageQualification.propertyInsurance"
                                onChange={formik.handleChange}
                                value={formik.values.
                                debt?.mortgageQualification?.propertyInsurance || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label=
                                "Total Debt Service Ratio"
                                name="debt.mortgageQualification.totalDebtServiceRatio"
                                onChange={formik.handleChange}
                                value={formik.values
                                .debt?.mortgageQualification?.totalDebtServiceRatio || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={sx.formItems}
                                fullWidth
                                label="Gross Debt Service Ratio"
                                name="debt.mortgageQualification.grossDebtServiceRatio"
                                onChange={formik.handleChange}
                                value={formik.values.
                                debt?.mortgageQualification?.grossDebtServiceRatio || ''}
                                variant="outlined"
                                type="number"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PercentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                                </Paper>
                            </TaxAccordian>
                            <Button fullWidth variant="contained"
                            disabled={formik.isSubmitting} type="submit">
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
});
