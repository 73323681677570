import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import PercentIcon from '@mui/icons-material/Percent';
import { globalSettingsStyles } from '../../utils';

interface IExpenseDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
}

export function ExpenseDrawer({ open, handleClose, settings }: IExpenseDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel="Edit Thresholds"
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Transportation"
            name="expenses.thresholds.transportationTotal"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.transportationTotal || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Housing"
            name="expenses.thresholds.housingTotal"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.housingTotal || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Housing Utilities"
            name="expenses.thresholds.housingUtilities"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.housingUtilities || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Personal Care Clothing"
            name="expenses.thresholds.personalCareClothing"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.personalCareClothing || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Personal Care Groceries"
            name="expenses.thresholds.personalCareGroceries"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.personalCareGroceries || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Personal Care Medical"
            name="expenses.thresholds.personalCareMedical"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.personalCareMedical || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Recreation"
            name="expenses.thresholds.recreationTotal"
            onChange={formik.handleChange}
            value={formik.values.expenses?.thresholds?.recreationTotal || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}
