import * as React from 'react';
import {
  DataGrid,
  // GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { appContext } from '@utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SuperAdmin } from '@graphql';
import SearchIcon from '@mui/icons-material/Search';
import { ConfirmationModal } from '@components';
import { ReactComponent as DeleteOutlined } from '@assets/svg/DeleteOutlined.svg';
import { ManagerDrawer } from '../ManagerDrawer';

interface ManagersCustomToolbarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

function CustomToolbar({ searchQuery, setSearchQuery }: ManagersCustomToolbarProps) {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <div style={{ display: `flex`, alignItems: `center` }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoFocus
          style={{ marginRight: 16, width: `23rem` }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" onClick={handleClose}>
          Create Manager
        </Button>
      </div>
      <ManagerDrawer open={open} handleClose={handleClose} />
    </GridToolbarContainer>
  );
}
export const AllManagersTable = observer(() => {
  const [manager, setManager] = useState<SuperAdmin | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleUpdateModal = useCallback(() => {
    setOpenUpdateModal(!openUpdateModal);
  }, [openUpdateModal]);

  const {
    managersStore: { managers, allManagersResponse, deleteManager, fetchAllManagers },
  } = useContext(appContext);

  const handleDeleteManager = async () => {
    if (!manager) {
      return;
    }

    const result = await deleteManager({ id: manager?.id });

    if (result) {
      await fetchAllManagers();
      handleDeleteModal();
    }
  };

  const columns = React.useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'name', headerName: 'Name', disableColumnMenu: true, flex: 1 },
      { field: 'role', headerName: 'Role', disableColumnMenu: true, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Admin"
            onClick={() => {
              setManager(params.row);
              handleUpdateModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Admin"
            onClick={() => {
              setManager(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [handleDeleteModal, handleUpdateModal],
  );

  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredAdmins = React.useMemo(
    () =>
      managers.filter((admin: SuperAdmin) =>
        ['email', 'name', 'role'].some((key) =>
          typeof admin[key as keyof SuperAdmin] === 'string' &&
          (admin[key as keyof SuperAdmin] as string).toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      ),
    [searchQuery, managers],
  );

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={filteredAdmins}
        columns={columns}
        isRowSelectable={() => false}
        loading={allManagersResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: { searchQuery, setSearchQuery },
        }}
      />
      <ManagerDrawer
        open={openUpdateModal}
        handleClose={handleUpdateModal}
        admin={manager as any}
        isEdit
      />
      <ConfirmationModal
        icon={<DeleteOutlined />}
        title={`Are you sure you want to remove ${manager?.name} from ElektraFi platform?`}
        onClose={handleDeleteModal}
        isOpen={openDeleteModal}
        variant="destructive"
        confirmationText={manager?.name || ''}
        onConfirm={handleDeleteManager}
        confirmationButtonLabel="Delete Organization"
      >
        <p>Please confirm if you would like to remove access to ElektraFi for this Admin.</p>
        <p>To proceed with the deletion, please type the administrator&apos;s name:</p>
        <strong>{manager?.name}</strong>
      </ConfirmationModal>
    </div>
  );
});
