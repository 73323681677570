/* eslint-disable react/require-default-props */
import Box from '@mui/material/Box';
import { useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const CustomInput = styled(InputBase)(() => ({
  '&.MuiInputBase-root ': {
    border: '1px solid #DDE1EE',
    borderRadius: '1rem',
    backgroundColor: 'white',
    padding: '4px 16px',
    height: '48px',

    fontFamily: 'Inter',
    fontSize: 16,
    color: '#929EC7',
    fontWeight: 400,
    lineHeight: '24px',
  },

  '& svg': {
    color: '#445488',
  },
}));

const makeStyles = () => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    alignItems: 'flex-start',
  },
  labelDefault: {
    color: '#232A43',
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    paddingLeft: '0.75rem',
  },
});

interface PasswordInputProps {
  value: string;
  inputId: string;
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PasswordInput({ value, inputId, onChange, label, name }: PasswordInputProps) {
  const [showPassword, setShow] = useState(false);
  const sx = makeStyles();
  return (
    <Box sx={sx.inputContainer}>
      <label htmlFor={inputId} style={sx.labelDefault}>
        {label}
      </label>

      <CustomInput
        id={inputId}
        fullWidth
        name={name}
        onChange={onChange}
        placeholder={label}
        value={value}
        type={(showPassword && 'text') || 'password'}
        required
        autoComplete="on"
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setShow(!showPassword)}>
              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
}
