import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import PercentIcon from '@mui/icons-material/Percent';
import { globalSettingsStyles } from '../../utils';

interface IRetirementDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
}

export function RetirementDrawer({ open, handleClose, settings }: IRetirementDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel="Edit Retirement Overview"
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Inflation Rate"
            name="retirement.inflationRate"
            onChange={formik.handleChange}
            value={formik.values.retirement?.inflationRate || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Appreciation Rate"
            name="retirement.appreciationRate"
            onChange={formik.handleChange}
            value={formik.values.retirement?.appreciationRate || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Income Required At Retirement"
            name="retirement.incomeRequiredAtRetirement"
            onChange={formik.handleChange}
            value={formik.values.retirement?.incomeRequiredAtRetirement || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Defined Contribution Max"
            name="retirement.definedContributionMaxLimit"
            onChange={formik.handleChange}
            value={formik.values.retirement?.definedContributionMaxLimit || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Group RRSP Contribution Max"
            name="retirement.groupRrspContributionMaxLimit"
            onChange={formik.handleChange}
            value={formik.values.retirement?.groupRrspContributionMaxLimit || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="RRSP Contribution Max"
            name="retirement.rrspContributionMaxLimit"
            onChange={formik.handleChange}
            value={formik.values.retirement?.rrspContributionMaxLimit || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="TFSA Contribution Max"
            name="retirement.tfsaContributionMaxLimit"
            onChange={formik.handleChange}
            value={formik.values.retirement?.tfsaContributionMaxLimit || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Max CPP Payout"
            name="retirement.maxCppPayout"
            onChange={formik.handleChange}
            value={formik.values.retirement?.maxCppPayout || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Max OAS Pauout"
            name="retirement.maxOasPayout"
            onChange={formik.handleChange}
            value={formik.values.retirement?.maxOasPayout || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="OAS Starting Age"
            name="retirement.oasStartingAge"
            onChange={formik.handleChange}
            value={formik.values.retirement?.oasStartingAge || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}
