import React, { useContext } from 'react';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SettingsContainer } from '@components';
import { GlobalSettingsDebtTypes } from '../../utils';
import { DebtDrawer } from './DebtDrawer';

export const DebtSettings = observer(() => {
  const [isDebtDrawerOpen, setIsDebtDrawerOpen] = React.useState(false);
  const [debtType, setDebtType] = React.useState<GlobalSettingsDebtTypes>(
    GlobalSettingsDebtTypes.REFINANCE_WITHOUT_MORTGAGE,
  );

  const handleDebtDrawerOpen = (_debtType: GlobalSettingsDebtTypes) => {
    setDebtType(_debtType);
    setIsDebtDrawerOpen(true);
  };

  const {
    userStore: { settings },
  } = useContext(appContext);

  const sections = [
    {
      title: 'Refinancing Without Mortgage',
      onEditClick: () => handleDebtDrawerOpen(GlobalSettingsDebtTypes.REFINANCE_WITHOUT_MORTGAGE),
      items: [
        {
          key: 'Interest Rate',
          value: `${settings?.debt?.refinancingWithoutMortgage?.interestRate}%`,
        },
        {
          key: 'Total Years To Pay Debt',
          value: `${settings?.debt?.refinancingWithoutMortgage?.totalYearsToPayDebt}`,
        },
      ],
    },
    {
      title: 'Refinancing Mortgage',
      onEditClick: () => handleDebtDrawerOpen(GlobalSettingsDebtTypes.REFINANCE_MORTGAGE),
      items: [
        { key: 'Interest Rate', value: `${settings?.debt?.refinancingMortgage?.interestRate}%` },
        {
          key: 'Total Years To Pay Debt',
          value: `${settings?.debt?.refinancingMortgage?.totalYearsToPayDebt}`,
        },
      ],
    },
    {
      title: 'Refinancing All Debts',
      onEditClick: () => handleDebtDrawerOpen(GlobalSettingsDebtTypes.REFINANCE_ALL_DEBTS),
      items: [
        { key: 'Interest Rate', value: `${settings?.debt?.refinancingByAllDebts?.interestRate}%` },
        {
          key: 'Total Years To Pay Debt',
          value: `${settings?.debt?.refinancingByAllDebts?.totalYearsToPayDebt}`,
        },
      ],
    },
    {
      title: 'Mortgage Qualifier',
      onEditClick: () => handleDebtDrawerOpen(GlobalSettingsDebtTypes.MORTGAGE_QUALIFIER),
      items: [
        { key: 'Down Payment', value: `${settings?.debt?.mortgageQualification?.downPayment}%` },
        {
          key: 'Total Years To Pay Debt',
          value: `${settings?.debt?.mortgageQualification?.totalYearsToPayDebt}`,
        },
        { key: 'Interest Rate', value: `${settings?.debt?.mortgageQualification?.interestRate}%` },
        {
          key: 'Property Insurance',
          value: `${settings?.debt?.mortgageQualification?.propertyInsurance}%`,
        },
        {
          key: 'Total Debt Service Ratio',
          value: `${settings?.debt?.mortgageQualification?.totalDebtServiceRatio}%`,
        },
        {
          key: 'Gross Debt Service Ratio',
          value: `${settings?.debt?.mortgageQualification?.grossDebtServiceRatio}%`,
        },
      ],
    },
  ];

  return (
    <>
      <SettingsContainer title="Debt" sections={sections} />
      <DebtDrawer
        open={isDebtDrawerOpen}
        handleClose={() => setIsDebtDrawerOpen(false)}
        debtType={debtType}
        settings={settings}
      />
    </>
  );
});
