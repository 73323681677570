import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  TaxSettings,
  EmergencySettings,
  InsuranceSettings,
  ExpensesSettings,
  DebtSettings,
  RetirementSettings,
} from './_';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function SettingsPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} centered onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Tax" {...a11yProps(0)} />
          <Tab label="Emergency Savings" {...a11yProps(1)} />
          <Tab label="Insurance" {...a11yProps(2)} />
          <Tab label="Expenses" {...a11yProps(3)} />
          <Tab label="Debt" {...a11yProps(4)} />
          <Tab label="Retirement" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TaxSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmergencySettings />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InsuranceSettings />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ExpensesSettings />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DebtSettings />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <RetirementSettings />
      </TabPanel>
    </Box>
  );
}
