import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { appContext, paths } from '@utils';
import { UserType } from '@graphql';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import { CustomButton, Input, PasswordInput } from '@components';
import { Link } from 'react-router-dom';
import { Cover } from '../../components/Cover';

const makeStyles = () => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    overflow: 'hidden',
  },
  formContainer: {
    backgroundColor: 'white',
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formContent: {
    margin: 'auto',
    width: '24.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  logo: {
    maxWidth: '10rem',
    padding: '2rem 4rem 0',
  },
  formTitle: {
    fontFamily: 'Urbanist',
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: '3rem',
    color: '#232A43',
  },
  formInfo: {
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    color: '#445488',
  },
  forgotPassword: {
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    color: '#445488',
    textAlign: 'start',
  },
});

export function LoginPage() {
  const sx = makeStyles();
  const {
    userStore: { logIn },
  } = useContext(appContext);
  const formik = useFormik({
    initialValues: { email: '', password: '', type: UserType.SuperAdmin },
    onSubmit: async (values) => {
      // await signIn(values.email, values.password)
      await logIn(values);
    },
  });

  return (
    <Box sx={sx.pageContainer}>
      {/* Left side */}
      <Cover />
      {/* Right side */}
      <Box sx={sx.formContainer}>
        <Box component="form" onSubmit={formik.handleSubmit} sx={sx.formContent}>
          <Typography sx={sx.formTitle}>Welcome! 👋</Typography>
          <Typography sx={sx.formInfo}>Please enter your details to sign in.</Typography>
          <Box>
            <Input
              value={formik.values.email}
              inputId="email-input"
              label="Email"
              name="email"
              onChange={formik.handleChange}
            />
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.375rem">
            <PasswordInput
              inputId="password-input"
              value={formik.values.password}
              label="Password"
              name="password"
              onChange={formik.handleChange}
            />

            <Typography sx={sx.forgotPassword}>
              <Link style={{ color: 'inherit', textDecoration: 'none' }} to={paths.forgot}>
                Forgot Password?
              </Link>
            </Typography>
          </Box>

          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Sign In
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
