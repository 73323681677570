import React, { useContext, useEffect } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { appContext } from '@utils';
import { FieldArray, Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY, TaxSlab } from '@graphql';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
  formItems: {
    margin: '1rem 0',
  },
  formIcons: {
    cursor: 'pointer',
  },
  accordianPaper: { padding: '1rem', margin: '1rem 0', textAlign: 'end' },
});

const PROVINCES: Array<{ name: string; value: string }> = [
  { name: 'Federal', value: 'federal' },
  { name: 'Alberta', value: 'ab' },
  { name: 'British Columbia', value: 'bc' },
  { name: 'Manitoba', value: 'mb' },
  { name: 'New Brunswick', value: 'nb' },
  { name: 'Newfoundland and Labrador', value: 'nl' },
  { name: 'Nova Scotia', value: 'ns' },
  { name: 'Northwest Territories', value: 'nt' },
  { name: 'Nunavut', value: 'nu' },
  { name: 'Ontario', value: 'on' },
  { name: 'Prince Edward Island', value: 'pe' },
  { name: 'Quebec', value: 'qc' },
  { name: 'Saskatchewan', value: 'sk' },
  { name: 'Yukon Territories', value: 'yt' },
];

interface IProps {
  name: string;
  children: React.ReactNode;
}

function TaxAccordian({ name, children }: IProps) {
  return (
    <Accordion sx={{ margin: '1rem 0' }} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ color: 'text.primary' }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'center' }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export const TaxSettingsOld = observer(() => {
  const sx = makeStyles();
  const {
    userStore: { settings, getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);
  useEffect(() => {
    getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
  }, [getCurrentSession]);
  return (
    <Formik
      initialValues={settings}
      enableReinitialize
      onSubmit={async (values) =>
        (await updateSettings(values)) &&
        (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
      }
    >
      {(formik) => (
        <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
          <Grid sx={sx.pageContainer} container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                  sx={sx.formItems}
                  fullWidth
                  label="CPP Income Threshold"
                  name="tax.cppIncomeThreshold"
                  onChange={formik.handleChange}
                  value={formik.values.tax?.cppIncomeThreshold || ''}
                  variant="outlined"
                  type="number"
                  required
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                    ),
                  }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="CPP Rate"
                name="tax.cppCredit.contributionRate"
                onChange={formik.handleChange}
                value={formik.values.tax?.cppCredit?.contributionRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={sx.formItems}
                fullWidth
                label="CPP Threshold"
                name="tax.cppCredit.threshold"
                onChange={formik.handleChange}
                value={formik.values.tax?.cppCredit?.threshold || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                sx={sx.formItems}
                fullWidth
                label="EI Rate"
                name="tax.eiCredit.contributionRate"
                onChange={formik.handleChange}
                value={formik.values.tax?.eiCredit?.contributionRate || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                sx={sx.formItems}
                fullWidth
                label="EI Threshold"
                name="tax.eiCredit.threshold"
                onChange={formik.handleChange}
                value={formik.values.tax?.eiCredit?.threshold || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                sx={sx.formItems}
                fullWidth
                label="Personal Credit"
                name="tax.personalCredit"
                onChange={formik.handleChange}
                value={formik.values.tax?.personalCredit || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NumbersIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                sx={sx.formItems}
                fullWidth
                label="Refundable Tax Claim"
                name="tax.refundableTaxClaim"
                onChange={formik.handleChange}
                value={formik.values.tax?.refundableTaxClaim || ''}
                variant="outlined"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {PROVINCES.map((province) => (
                <FieldArray
                  key={province.name}
                  name={`tax.${province.value}`}
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <TaxAccordian name={province.name}>
                      {(formik as any).values.tax?.[province.value]?.map(
                        (taxSlab: TaxSlab, index: number) => {
                          const isLast =
                            index === (formik as any).values.tax[province.value].length - 1;
                          const isSecondLast =
                            index === (formik as any).values.tax[province.value].length - 2;
                          return (
                            <Paper sx={sx.accordianPaper} elevation={2}>
                              <Grid container justifyContent="space-between">
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                  <TextField
                                    sx={sx.formItems}
                                    fullWidth
                                    label="Limit"
                                    name={`tax.${province.value}.${index}.limit`}
                                    onChange={(e) => {
                                      if (isSecondLast) {
                                        formik.setFieldValue(
                                          `tax.${province.value}.${index + 1}.limit`,
                                          parseInt(e.currentTarget.value, 10),
                                        );
                                      }
                                      if (!isLast) formik.handleChange(e);
                                    }}
                                    disabled={isLast}
                                    value={(isLast && 'Infinity') || taxSlab.limit}
                                    variant="outlined"
                                    type={(isLast && 'text') || 'number'}
                                    required
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <NumbersIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                  <TextField
                                    sx={sx.formItems}
                                    fullWidth
                                    label="Rate"
                                    name={`tax.${province.value}.${index}.rate`}
                                    onChange={formik.handleChange}
                                    value={taxSlab.rate}
                                    variant="outlined"
                                    type="number"
                                    required
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PercentIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <CloseIcon
                                    sx={sx.formIcons}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          );
                        },
                      ) || []}
                      <AddIcon
                        sx={sx.formIcons}
                        onClick={() =>
                          arrayHelpers.push({
                            limit:
                              (formik as any).values?.tax[province.value]?.slice(-1)?.limit || 0,
                            rate: 0,
                          } as TaxSlab)
                        }
                      />
                    </TaxAccordian>
                  )}
                />
              ))}
              <Button fullWidth variant="contained" disabled={formik.isSubmitting} type="submit">
                SAVE
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});
