export const COLORS = {
  ELEKTRAFI_LIGHT: '#E7ECFE',
  ELEKTRAFI_NEUTRAL: '#2F5DF5',
  ELEKTRAFI_DARK: '#051A61',
  ELEKTRAFI_CYAN_LIGHT: '#E9F7FB',
  ELEKTRAFI_CYAN_NEUTRAL: '#5FC6E3',
  ELEKTRAFI_CYAN_DARK: '#0F4757',
  ELEKTRAFI_PINK_NEUTRAL: '#E128D1',

  NEUTRAL_LIGHT_0: '#929EC7',
  NEUTRAL_LIGHT_1: '#BCC3DC',
  NEUTRAL_LIGHT_2: '#DDE1EE',
  NEUTRAL_LIGHT_3: '#EEF0F6',
  NEUTRAL_DARK_0: '#445488',
  NEUTRAL_DARK_1: '#333F66',
  NEUTRAL_DARK_2: '#232A43',
  NEUTRAL_DARK_3: '#111522',
  NEUTRAL_BLACK: '#000000',
  NEUTRAL_WHITE: '#FFFFFF',
  NEUTRAL_100: '#1E202C',

  POSITIVE_LIGHT: '#CEFDE2',
  POSITIVE_NEUTRAL: '#09C35A',
  POSITIVE_DARK: '#079243',

  NEGATIVE_LIGHT: '#F7D4D9',
  NEGATIVE_NEUTRAL: '#AB2135',
  NEGATIVE_DARK: '#811828',

  WARNING_LIGHT: '#FFF4CC',
  WARNING_NEUTRAL: '#FFC800',
  WARNING_DARK: '#B58E00',

  INFO_LIGHT: '#E7ECFE',
  INFO_NEUTRAL: '#2F5DF5',
  INFO_DARK: '#051A61',

  HELP_LIGHT: '#DDCEFD',
  HELP_NEUTRAL: '#6F30F5',
  HELP_DARK: '#330792',

  STATUS_NEGATIVE_NEUTRAL: '#AB2135',
};
