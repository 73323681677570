import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import PercentIcon from '@mui/icons-material/Percent';
import { globalSettingsStyles } from '../../utils';

interface IEmergencySavingsDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
}

export function EmergencySavingsDrawer({
  open,
  handleClose,
  settings,
}: IEmergencySavingsDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel="Edit Interest Rate"
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Interest Rate"
            name="emergencySavings.interestRate"
            onChange={formik.handleChange}
            value={formik.values.emergencySavings?.interestRate || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}
