import React, { useContext, useMemo, useState } from 'react';
import { TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Advisor, ALL_ADVISORS_QUERY, UpdateAdvisorInput } from '@graphql';
import { CustomButton, CustomDrawer } from '@components';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { appContext } from '@utils';
import * as Yup from 'yup';
import { Add, BorderColorOutlined } from '@mui/icons-material';
import { ReactComponent as DashedDivider } from '@assets/svg/DashedDivider.svg';
import { COLORS } from '@styles';

const makeStyles = () => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  editHeader: {
    paddingBottom: '1rem',
    borderBottom: `1px dashed ${COLORS.NEUTRAL_LIGHT_3}`,
  },
  dashedDivider: {
    width: '100%',
  },
  formContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0 1rem',
  } as React.CSSProperties,
  headerClientId: {
    color: COLORS.NEUTRAL_LIGHT_0,
  },
  formItems: {
    margin: '1rem 0',
    borderRadius: '1rem',
  },
  inputBorder: {
    borderRadius: '1rem',
  },
  section: {
    margin: '1.5rem 0 0.5rem 0',
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    borderTop: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    marginTop: 'auto',
  },
});

interface IProps {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  advisor?: Advisor | null;
}

type TAdvisorInput = Omit<
  UpdateAdvisorInput & {
    password: string;
    email: string;
    phoneNumber: number | string;
  },
  'phone'
>;

export function AdvisorsDrawer({ open, handleClose, isEdit, advisor }: IProps) {
  const sx = makeStyles();
  const [showPassword, setShow] = useState<boolean>(false);
  const {
    advisorStore: { createAdvisor, updateAdvisor },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const initialValues = useMemo<TAdvisorInput>(
    () => ({
      id: advisor?.id || '',
      firstName: advisor?.firstName || '',
      lastName: advisor?.lastName || '',
      company: advisor?.company || '',
      phoneNumber: advisor?.phone ? Number(advisor.phone.replace(/[^0-9]+/g, '')) : '',
      title: advisor?.title || '',
      email: '',
      password: '',
      edit: isEdit,
    }),
    [advisor, isEdit],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address.')
      .when([], (_, schema) => (isEdit ? schema : schema.required('Email is required.'))),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    password: Yup.string()
      .when([], (_, schema) => (isEdit ? schema : schema.required('Password is required.')))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])/,
        `Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character.`,
      )
      .min(8, 'Password must be at least 8 characters long.'),
    company: Yup.string().required('Company is required.'),
    phoneNumber: Yup.number().required('Phone is required.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      let result;

      if (isEdit) {
        const payload = {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          phone: values.phoneNumber?.toString() || '',
          title: values.title,
        };

        result = await updateAdvisor(payload);
      } else {
        const payload = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          title: values.title,
          company: values.company,
          phone: values.phoneNumber?.toString() || '',
        };
        result = await createAdvisor(payload);
      }

      if (result) {
        await getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
        formikHelpers.resetForm();
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel={isEdit ? 'Edit Advisor' : 'Add Advisor'}
      headerIcon={isEdit ? <BorderColorOutlined /> : <Add />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          {isEdit && (
            <>
              <Typography variant="h6">{`${advisor?.firstName} ${advisor?.lastName}`}</Typography>
              <Typography sx={sx.headerClientId} variant="subtitle1">
                {advisor?.id}
              </Typography>
              <DashedDivider style={sx.dashedDivider} />
            </>
          )}
          <Typography sx={sx.section} variant="subtitle2">
            Advisor Information
          </Typography>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Job Title"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
          />
          {!isEdit && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              type="email"
              InputProps={{ style: sx.inputBorder }}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          )}
          {!isEdit && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              variant="outlined"
              type={(showPassword && 'text') || 'password'}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                style: sx.inputBorder,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShow(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            type="number"
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.phoneNumber}
            helperText={formik.errors.phoneNumber}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Company"
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
            variant="outlined"
            InputProps={{ style: sx.inputBorder }}
            error={!!formik.errors.company}
            helperText={formik.errors.company}
          />
        </div>
        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            {isEdit ? 'Save Changes' : 'Add Advisor'}
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}

AdvisorsDrawer.defaultProps = {
  isEdit: false,
  advisor: {},
};
