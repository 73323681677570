import { createTheme, ThemeOptions } from '@mui/material';

export const dark: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#062121',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Urbanist',
    },
    h2: {
      fontFamily: 'Urbanist',
    },
    h3: {
      fontFamily: 'Urbanist',
    },
    h4: {
      fontFamily: 'Urbanist',
    },
    h5: {
      fontFamily: 'Urbanist',
    },
    h6: {
      fontFamily: 'Urbanist',
    },
    body1: {
      fontFamily: 'Inter',
    },
    body2: {
      fontFamily: 'Inter',
    },
    subtitle1: {
      fontFamily: 'Inter',
    },
    subtitle2: {
      fontFamily: 'Inter',
    },
    button: {
      fontFamily: 'system-ui, Inter',
      textTransform: 'none',
      fontSize: '1rem',
    },
    fontFamily: 'Inter',
  },
};
export const light: ThemeOptions = {
  palette: {
    background: {
      default: '#D9E4DD',
    },
  },
};

export const darkTheme = createTheme(dark);
export const lightTheme = createTheme(light);
