import { COLORS } from '@styles';

export enum ProvincesEnum {
  federal = 'Federal',
  ab = 'Alberta',
  bc = 'British Columbia',
  mb = 'Manitoba',
  nb = 'New Brunswick',
  nl = 'Newfoundland and Labrador',
  ns = 'Nova Scotia',
  nt = 'Northwest Territories',
  nu = 'Nunavut',
  on = 'Ontario',
  pe = 'Prince Edward Island',
  qc = 'Quebec',
  sk = 'Saskatchewan',
  yt = 'Yukon Territories',
}

export const PROVINCES = (Object.keys(ProvincesEnum) as Array<keyof typeof ProvincesEnum>).map(
  (key) => ({
    name: ProvincesEnum[key],
    value: key,
  }),
);

export const globalSettingsStyles = () => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  formContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0 1rem',
  } as React.CSSProperties,
  formItems: {
    margin: '1rem 0',
    borderRadius: '1rem',
  },
  inputBorder: {
    borderRadius: '1rem',
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    /* Firefox */
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  inputIcon: {
    color: COLORS.NEUTRAL_LIGHT_1,
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    borderTop: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    marginTop: 'auto',
  },
  section: {
    margin: '0 0 1rem 0',
  },
});

export enum GlobalSettingsDebtTypes {
  REFINANCE_WITHOUT_MORTGAGE = 'Refinancing Without Mortgage',
  REFINANCE_MORTGAGE = 'Refinancing Mortgage',
  REFINANCE_ALL_DEBTS = 'Refinancing All Debts',
  MORTGAGE_QUALIFIER = 'Mortgage Qualifier',
}
