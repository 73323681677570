import React, { useContext } from 'react';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SettingsContainer } from '@components';
import { RetirementDrawer } from './RetirementDrawer';

export const RetirementSettings = observer(() => {
  const [isRetirementDrawerOpen, setIsRetirementDrawerOpen] = React.useState(false);
  const {
    userStore: { settings },
  } = useContext(appContext);

  const sections = [
    {
      title: 'Retirement',
      onEditClick: () => setIsRetirementDrawerOpen(true),
      items: [
        { key: 'Inflation Rate', value: `${settings?.retirement?.inflationRate}%` },
        { key: 'Appreciation Rate', value: `${settings?.retirement?.appreciationRate}%` },
        {
          key: 'Income Required At Retirement',
          value: `${settings?.retirement?.incomeRequiredAtRetirement}%`,
        },
        {
          key: 'Defined Contribution Max',
          value: `${settings?.retirement?.definedContributionMaxLimit}%`,
        },
        {
          key: 'Group RRSP Contribution Max',
          value: `${settings?.retirement?.groupRrspContributionMaxLimit}%`,
        },
        {
          key: 'RRSP Contribution Max',
          value: `${settings?.retirement?.rrspContributionMaxLimit}%`,
        },
        {
          key: 'TFSA Contribution Max',
          value: `${settings?.retirement?.tfsaContributionMaxLimit}%`,
        },
        { key: 'Max CPP Payout', value: `${settings?.retirement?.maxCppPayout}%` },
        { key: 'Max OAS Pauout', value: `${settings?.retirement?.maxOasPayout}%` },
        { key: 'OAS Starting Age', value: `${settings?.retirement?.oasStartingAge}%` },
      ],
    },
  ];
  return (
    <>
      <SettingsContainer title="Retirement" sections={sections} />
      <RetirementDrawer
        open={isRetirementDrawerOpen}
        handleClose={() => setIsRetirementDrawerOpen(false)}
        settings={settings}
      />
    </>
  );
});
